<template>
  <div :class="`datepicker-wrapper-custom ${cssClass}`">
    <input id="calendar-datepicker"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  easepick,
  RangePlugin,
  LockPlugin,
  AmpPlugin,
} from '@easepick/bundle';
import { DATE_FORMAT } from '@/components/calendar/config';
import moment from 'moment';
// eslint-disable-next-line
import datepickerDefaultCss from '!!raw-loader!@easepick/bundle/dist/index.css';
// eslint-disable-next-line
import datepickerCustomCss from '!!raw-loader!@/assets/css/datepickerCustom.css';

export default {
  mounted() {
    this.initDatePicker();
    this.setNumberOfVisibleMonths();
    window.addEventListener('resize', this.setNumberOfVisibleMonths);
  },
  destroyed() {
    if (this.picker) {
      this.picker.destroy();
      window.removeEventListener('resize', this.setNumberOfVisibleMonths);
    }
  },
  props: {
    // Computed props
    disabledDates: {
      required: false,
      type: Array,
    },
    manualBlockDates: {
      required: false,
      type: Array,
    },
    minStayBlockDates: {
      required: false,
      type: Array,
    },

    start: {
      required: false,
    },
    end: {
      required: false,
    },
    cssClass: {
      required: false,
      default: '',
    },
  },
  watch: {
    end(value) {
      if (!value) {
        this.selectingActive = false;
        this.picker.renderAll();
      }
      this.resetRangeToStartEnd();
    },
    start() {
      this.resetRangeToStartEnd();
    },
  },
  data() {
    return {
      picker: undefined,
      datepickerStart: undefined,
      datepickerEnd: undefined,
      selectingActive: false,
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'editReservationId']),
    minDatepickerDate() {
      // if is admin and if we are in edit reservation modal, than we should be able to go to past.
      if (this.isAdmin && this.editReservationId) {
        return null;
      }
      return moment().format(DATE_FORMAT);
    },
    maxDatepickerDate() {
      return moment().add(35, 'months').endOf('month').format(DATE_FORMAT);
    },
  },
  methods: {
    setNumberOfVisibleMonths() {
      if (!this.picker) {
        return;
      }
      const { options } = this.picker;
      if (options.calendars === 2 && window.innerWidth >= 480) {
        const { container } = this.picker.ui;
        const unixDate = moment(this.maxDatepickerDate).unix() * 1000;
        if (container.querySelector(`.day.unit[data-time="${unixDate}"]`)) {
          this.picker.gotoDate(moment(this.maxDatepickerDate).subtract(1, 'month').format(DATE_FORMAT));
        }
      }
    },
    resetRangeToStartEnd() {
      if (this.start && this.end) {
        const rangePlugin = this.picker.PluginManager.getInstance('RangePlugin');
        rangePlugin.setStartDate(this.start);
        rangePlugin.setEndDate(moment(this.end).subtract(1, 'day').format(DATE_FORMAT));
        this.picker.renderAll();
      }
    },
    initDatePicker() {
      const datepickerEl = document.getElementById('calendar-datepicker');
      const datepickerCss = datepickerDefaultCss + datepickerCustomCss;
      if (!datepickerEl) {
        return;
      }
      this.datepickerStart = this.start;
      this.datepickerEnd = this.end;
      // eslint-disable-next-line
      this.picker = new easepick.create({
        element: datepickerEl,
        plugins: [RangePlugin, LockPlugin, AmpPlugin],
        css: datepickerCss,
        format: 'YYYY-MM-DD',
        calendars: 2,
        grid: 1,
        inline: true,
        lang: this.$i18n.locale === 'sr' ? 'sr-ME' : 'en',
        LockPlugin: {
          // eslint-disable-next-line
          filter: (date) => {
            if (this.selectingActive) {
              if (this.minStayBlockDates && this.minStayBlockDates.length) {
                if (this.minStayBlockDates.includes(moment(this.datepickerStart).format(DATE_FORMAT))) {
                  return !this.minStayBlockDates.includes(date.format(DATE_FORMAT));
                }
                return (this.disabledDates + this.minStayBlockDates).includes(date.format(DATE_FORMAT));
              }
            }
            if (this.disabledDates && this.disabledDates.length) {
              return this.disabledDates && this.disabledDates.includes(date.format(DATE_FORMAT));
            }
          },
          inseparable: true,
          minDate: this.minDatepickerDate,
          maxDate: this.maxDatepickerDate,
        },
        RangePlugin: {
          strict: true,
          locale: {
            one: this.$t('night'),
            few: this.$t('nights'),
            other: this.$t('nights'),
          },
        },
        AmpPlugin: {
          darkMode: false,
        },
        setup: (picker) => {
          picker.on('preselect', (e) => {
            if (e.detail.start) {
              this.datepickerStart = moment(e.detail.start).clone().format(DATE_FORMAT);
              this.datepickerEnd = moment(e.detail.start).clone().format(DATE_FORMAT);
              this.$emit('updateStartEnd', {
                start: this.datepickerStart,
                end: this.datepickerEnd,
              });
            }
            this.selectingActive = true;
          });
          picker.on('select', (e) => {
            this.selectingActive = false;
            if (e.detail.start && e.detail.end) {
              this.datepickerStart = moment(e.detail.start).clone().format(DATE_FORMAT);
              this.datepickerEnd = moment(e.detail.end).clone().format(DATE_FORMAT);
              this.$emit('updateStartEnd', {
                start: this.datepickerStart,
                end: this.datepickerEnd,
              });
            }
          });
          // mark manual block dates
          picker.on('view', (e) => {
            const { date, target } = e.detail;
            if (this.manualBlockDates && this.manualBlockDates.length) {
              if (this.manualBlockDates.includes(moment(date).format(DATE_FORMAT))) {
                target.classList.add('manual-blocked');
              }
            }
          });
        },
      });
      if (this.start) {
        this.picker.gotoDate(this.start);
        if (this.end) {
          this.resetRangeToStartEnd();
        }
      }
    },
  },
};
</script>
<style lang="scss">
.datepicker-wrapper-custom{
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  @media (min-width: 480px) {
    padding-top: 30px;
  }
  @media (max-width: 500px) {
    span.easepick-wrapper{
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  input#calendar-datepicker{
    display: none;
  }
}
.datepicker-wrapper-custom.pricing-preview{
  padding-top: 0px !important;
}
</style>
