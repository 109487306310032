<template>
  <div row fill-height>
      <div class="scroll-to-top"></div>
      <div class="mb-5">
        <filter-ads
          v-if="!hideAdsFilter"
          @update="filter.ads = $event; load()"
          :value="filter.ads"
          :key="`filter_ads_${filterKey}`"
        />
        <filter-dates
          @update="filter.start_at = $event[0]; filter.end_at = $event[1]; load()"
          :value="[filter.start_at, filter.end_at]"
          :key="`filter_dates_${filterKey}`"/>

      </div>
    <template v-if="loading">
      {{ $t('Loading') }}
    </template>
    <template v-else-if="activities.length">
      <v-timeline
        dense
        clipped
        align-top>
          <Log  v-for="(activity, i) in activities"
            :activity="activity"
            :key="i"/>
      </v-timeline>
      <pagination
        :resource="pagination"
        overwrite-path="/activity-log"
        @load="load"
      />
    </template>
    <template v-else>
      <div class="text-h5 mt-10">{{$t('No activities found')}}</div>
      <br/>
      <a href @click.prevent.stop="resetAllFilters">{{$t('Reset all filters')}}</a>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import Log from '@/components/activity-log/Log.vue';
import Pagination from '@/components/Pagination.vue';
import FilterAds from '@/components/Filters/FilterAds.vue';
import FilterDates from '@/components/Filters/FilterDates.vue';

export default {
  props: {
    ads: {
      type: Array,
      required: false,
    },
    hideAdsFilter: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  components: {
    Log,
    Pagination,
    FilterAds,
    FilterDates,
  },
  data: () => ({
    activities: [],
    pagination: {},
    filter: {
      ads: [],
      start_at: undefined,
      end_at: undefined,
    },
    filterKey: 1,
  }),
  computed: {
    ...mapGetters(['user', 'loading']),
  },
  mounted() {
    if (this.ads) {
      this.filter.ads = this.ads;
    }
    this.load();
  },
  methods: {
    load(url = '/activity-log') {
      this.$store.dispatch('loading', true);
      axios
        .get(url, {
          params: this.filter,
        })
        .then((response) => {
          this.scrollToTop(250);
          this.pagination = response.data;
          this.activities = response.data.data;
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    scrollToTop() {
      const element = document.querySelector('#scroll-to-top');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    resetAllFilters() {
      this.filter.ads = [];
      this.filter.start_at = undefined;
      this.filter.end_at = undefined;
      this.filterKey += 1;
      this.load();
    },
  },
};
</script>
