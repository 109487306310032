<template>
    <div>
        <template v-if="hasCustomPrice">
          <h3 class="mt-3 mb-4 text-center">{{ $t('Custom pricing') }}</h3>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr class="text-decoration-underline">
                  <td><b>{{ $t('Total') }}:</b></td>
                  <td class="text-no-wrap"><b>€ {{ customPrice.total }}</b></td>
                </tr>
                <tr>
                  <td>{{ $t('Service fee') }}:</td>
                  <td>€ {{ customPrice.serviceFee }}</td>
                </tr>
                <tr>
                  <td>{{ $t('TAX') }}:</td>
                  <td>€ {{ customPrice.taxOnServiceFee }}</td>
                </tr>
                <tr class="text-decoration-underline">
                  <td><b>{{ $t('What you’ll be earning') }}:</b></td>
                  <td><b>€ {{ customPrice.ownerTotal }}</b></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <template v-else>
          <div class="d-flex align-center justify-center mt-10">
            <v-icon class="mr-2">
              mdi-form-textbox
            </v-icon>
            <div>
              You must enter <b>Custom Price Total</b> amount to see the pricing!
            </div>
          </div>
        </template>
    </div>
</template>

<script>

export default {
  props: {
    customPrice: {
      required: true,
      type: Object,
    },
  },
  computed: {
    hasCustomPrice() {
      if (this.customPrice
       && this.customPrice.total
       && this.customPrice.ownerTotal
       && this.customPrice.serviceFee
       && this.customPrice.taxOnServiceFee) {
        return true;
      }

      return false;
    },
  },
};
</script>
