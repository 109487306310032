<template>
  <div v-if="$i18n.locale === 'sr' && helpCenterLinks[topic]">
    <a
      v-if="mode === 'link'"
      :href="helpCenterLinks[topic]"
      target="_blank"
      class="text-body-2 d-flex help-center-link"
      :class="cssClass"
    >
      {{ linkText ? linkText : $t('Learn more') }}
    </a>

    <v-btn
      v-else-if="mode === 'button'"
      icon
      color="primary"
      class="grey lighten-4"
      :class="cssClass"
      :href="helpCenterLinks[topic]"
      target="_blank"
      small
    >
      <v-icon small>
        mdi-help
      </v-icon>
    </v-btn>

    <v-alert
      v-else-if="mode === 'info'"
      type="info"
      icon="mdi-help"
      style="margin-inline: 0 !important;"
      :class="cssClass"
      dense
      text
    >
      <!-- {{ linkText ? linkText : $t('Learn more') }} -->
      <a
        :href="helpCenterLinks[topic]"
        target="_blank"
        class="text-body-2"
        color="primary"
      >
        {{ linkText ? linkText : $t('Learn more') }}
      </a>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    topic: {
      required: true,
      type: String,
    },
    mode: {
      required: false,
      type: String,
      default: 'link',
    },
    linkText: {
      required: false,
      type: String,
    },
    cssClass: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      helpCenterLinks: {
        'verify-email': 'https://bookaweb.com/sr/help-centar-za-vlasnike/korisnicki-nalog/verifikacija-email-adrese',
        'verify-government-id': 'https://bookaweb.com/sr/help-centar-za-vlasnike/korisnicki-nalog/verifikacija-licne-karte',
        'change-password': 'https://bookaweb.com/sr/help-centar-za-vlasnike/korisnicki-nalog/promena-sifre',
        'account-type': 'https://bookaweb.com/sr/help-centar-za-vlasnike/korisnicki-nalog/tip-naloga',
        'payment-method': 'https://bookaweb.com/sr/help-centar-za-vlasnike/korisnicki-nalog/nacin-placanja',
        'update-apartment': 'https://bookaweb.com/sr/help-centar-za-vlasnike/upravljanje-smestajem/azuriranje-informacija-o-smestaju',
        'create-apartment': 'https://bookaweb.com/sr/help-centar-za-vlasnike/upravljanje-smestajem/unosenje-smestaja',
        'create-basic-information': 'https://bookaweb.com/sr/help-centar-za-vlasnike/upravljanje-smestajem/unosenje-smestaja#osnovne-informacije',
        'create-structure': 'https://bookaweb.com/sr/help-centar-za-vlasnike/upravljanje-smestajem/unosenje-smestaja#struktura',
        'create-parking': 'https://bookaweb.com/sr/help-centar-za-vlasnike/upravljanje-smestajem/unosenje-smestaja#parking',
        'create-location': 'https://bookaweb.com/sr/help-centar-za-vlasnike/upravljanje-smestajem/unosenje-smestaja#lokacija',
        'create-tags': 'https://bookaweb.com/sr/help-centar-za-vlasnike/upravljanje-smestajem/unosenje-smestaja#opremljenost',
        'create-gallery': 'https://bookaweb.com/sr/help-centar-za-vlasnike/upravljanje-smestajem/unosenje-smestaja#fotografije',
        'create-price': 'https://bookaweb.com/sr/help-centar-za-vlasnike/upravljanje-smestajem/unosenje-smestaja#cena',
        'create-reservations': 'https://bookaweb.com/sr/help-centar-za-vlasnike/upravljanje-smestajem/unosenje-smestaja#rezervacije',
        'create-terms': 'https://bookaweb.com/sr/help-centar-za-vlasnike/upravljanje-smestajem/unosenje-smestaja#uslovi',
        'create-reservation-types': 'https://bookaweb.com/sr/help-centar-za-vlasnike/rezervacije/tipovi-rezervacija',
        'respond-to-inquiries': 'https://bookaweb.com/sr/help-centar-za-vlasnike/rezervacije/odgovaranje-na-upite',
        'reservations-advance-payment-status': 'https://bookaweb.com/sr/help-centar-za-vlasnike/rezervacije/avans#status-uplate-avansa',
        'calendar-rules': 'https://bookaweb.com/sr/help-centar-za-vlasnike/upravljanje-kalendarom/specijalna-pravila',
        'calendar-pricing-preview': 'https://bookaweb.com/sr/help-centar-za-vlasnike/upravljanje-smestajem/formiranje-cene',
        'calendar-availability': 'https://bookaweb.com/sr/help-centar-za-vlasnike/upravljanje-kalendarom/rucno-azuriranje-raspolozivosti#kako-zatvoriti-datume',
        'calendar-price-per-day': 'https://bookaweb.com/sr/help-centar-za-vlasnike/upravljanje-kalendarom/cena-po-danu',
      },
    };
  },
};
</script>
<style scoped>
.help-center-link {
  border-radius: 16px !important;
  padding-block: 6px;
  padding-inline: 8px;
  max-width: max-content;
  transition: all 0.2s ease;
  margin-left: -6px;
}
.help-center-link:hover {
  background: rgb(235, 235, 235);
}
</style>
