<template>
  <span>
    <modal
      :show="show"
      @close="show = false"
      @save="$emit('save'); show = false"
      save-text="Delete"
      :title="$t('ConfirmModal.title')"
      :description="$t('ConfirmModal.description')"
      hide-top-close-button
      max-width="400"
    />
    <v-tooltip  bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          class="cursor-pointer ml-3 text--link"
          dense
          v-bind="attrs"
          v-on="on"
          @click="show = true"
        >
          mdi-delete-outline
        </v-icon>
      </template>
      <span>{{ $t("Delete") }}</span>
    </v-tooltip>
  </span>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
};
</script>
