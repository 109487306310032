<template>
    <filter-wrapper
      :title="title"
      :isFilterApplied="isFilterApplied"
      :offsetPositionLeft="offsetPositionLeft"
      :showCancel="showCancel"
      @cancel="cancel"
      @clear="clear"
      @update="$emit('update', selected)"
    >
      <template v-slot:main>
        <div class="pl-5 pt-5">
          <v-text-field
            class="pr-5 pt-0 mb-2 mx-5"
            v-model="search"
            :label="$t('Search')"
            type="text"
            prepend-inner-icon="mdi-magnify"
            hide-details
          />
          <template v-if="!filteredResults.length">
            <v-alert> {{$t('No results')}} </v-alert>
          </template>
          <template v-else>
            <v-list dense
              v-if="isMultiSelect"
              :max-height="$vuetify.breakpoint.mdAndUp ? 400 : ''"
              class="overflow-y-auto"
              :class="{'pb-16': $vuetify.breakpoint.smAndDown}"
            >
              <v-list-item v-for="result in filteredResults"
                :key="result.id"
                dense
              >
                <v-checkbox
                  v-model="selected"
                  :value="result.id"
                  :label="result.name"
                  dense
                  class="mt-0 mb-0"
                />
              </v-list-item>
            </v-list>
            <v-list
              v-else
              :max-height="$vuetify.breakpoint.mdAndUp ? 400 : ''"
              class="overflow-y-auto px-5"
              :class="{'pb-16': $vuetify.breakpoint.smAndDown}"
            >
              <v-radio-group
                v-model="selected"
              >
                <v-radio
                  v-for="result in filteredResults"
                  :value="result.id"
                  :key="result.id"
                  :label="result.name"
                  class="my-2"
                >
                </v-radio>
              </v-radio-group>
            </v-list>
          </template>
        </div>
      </template>

    </filter-wrapper>
</template>
<script>
import FilterWrapper from '@/components/Filters/FilterWrapper.vue';
import axios from 'axios';

export default {
  components: {
    FilterWrapper,
  },
  props: {
    offsetPositionLeft: {
      type: Boolean,
      default: false,
    },
    isMultiSelect: {
      type: Boolean,
      default: true,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    value: {
      default: undefined,
      required: false,
    },
    statusFilter: {
      type: Array,
      default: undefined,
      required: false,
    },
    categoryFilter: {
      type: Number,
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      search: undefined,
      results: [],
      selected: [],
    };
  },
  mounted() {
    if (this.value) {
      this.selected = this.value;
    }
    this.load();
  },
  computed: {
    title() {
      if (this.isMultiSelect) {
        return `${this.$t('Listings')} ${this.selected.length ? ` · ${this.selected.length}` : ''}`;
      }
      const ad = this.results.find((result) => result.id === this.selected);
      if (ad && ad.name) {
        return ad.name;
      }
      return '';
    },
    filteredResults() {
      if (this.search) {
        return this.results.filter((result) => result.name.toLowerCase().includes(this.search.toLowerCase()));
      }
      return this.results;
    },
    isFilterApplied() {
      return Boolean(this.selected.length);
    },
  },
  methods: {
    cancel() {
      this.selected = this.value;
    },
    load(url = '/activity-log/filter-options/ads') {
      axios
        .get(url, {
          params: {
            status_filter: this.statusFilter,
            category_filter: this.categoryFilter,
          },
        })
        .then((response) => {
          this.results = response.data;
        });
    },
    clear() {
      this.selected = [];
      this.$emit('update', this.selected);
      this.search = undefined;
    },
  },
};
</script>
