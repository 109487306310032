<template>
    <li v-if="visible">
        <div v-if="template==='twoLine'">
            <template v-if="from">
              <strong>{{ $t(propertyTranslated) }}</strong> {{$t('activity changed')}} {{$t('activity from')}}:
              <br/>
              <v-icon class="cursor-pointer" dense>mdi-format-quote-close</v-icon>
              {{ from }}
              <v-icon class="cursor-pointer" dense>mdi-format-quote-close</v-icon>
              <br/>
              {{$t('activity to')}}
              <br/>
              <v-icon class="cursor-pointer" dense>mdi-format-quote-close</v-icon>
              {{to}}
              <v-icon class="cursor-pointer" dense>mdi-format-quote-close</v-icon>
            </template>
            <template v-else-if="to">
              <strong>{{ $t(propertyTranslated) }}</strong> <p class="word-break-all">"{{to}}"</p>
            </template>
        </div>
        <div v-else-if="template==='color'">
          <template v-if="from">
            <span class="font-weight-black">{{ $t('Color') }}</span>
            {{$t('activity changed')}} {{$t('activity from')}} <span class="circle-color" :style="{backgroundColor: from}"></span>
            {{$t('changed to')}} <span class="circle-color" :style="{backgroundColor: to}"></span>
            </template>
            <template v-else-if="to">
              <span class="font-weight-black">{{ $t('Color') }}</span>: <span class="circle-color" :style="{backgroundColor: to}"></span>
            </template>
        </div>
        <div v-else-if="template==='status'">
          <div>
            <span class="font-weight-black">Status</span> {{$t('changed to')}} "<b>{{to}}</b>"
          </div>
        </div>
        <div v-else-if="template==='payout'">
          <template v-if="property === 'data'">
            <div v-for="(val,key) in to" :key="key">
              <b>{{$t(translations[key])}}</b>: {{val}}
            </div>
          </template>
          <!-- <template v-else>
            <b>{{property}}</b>: {{to}}
          </template> -->
        </div>
        <div v-else-if="template==='calendar'">
          <div
            v-if="activity.event === 'calendar.manual.block'">
            Blocked from {{ activity.properties.attributes.start }} - {{ activity.properties.attributes.end }}
          </div>
        </div>
        <div v-else-if="template==='gallery'">
          <div v-if="property === 'images_added'" v-html="$tc('Activity Log Property.images_added', activity.properties.attributes.images_added)"></div>
          <div v-if="property === 'images_removed'" v-html="$tc('Activity Log Property.images_removed', activity.properties.attributes.images_removed)"></div>
        </div>
        <div v-else-if="template==='boolean'">
          <strong>{{ $t(propertyTranslated) }}</strong>: "{{activity.properties.attributes[property] ? $t('Yes') : $t('No') }}"
          <!-- <div v-if="property === 'paid'" v-html="$tc('Activity Log reservation.paid', activity.properties.attributes.paid)"></div> -->
        </div>
        <div v-else-if="template==='changeString'" v-html="$t(changeString[property])"></div>
        <div v-else-if="template==='parking'">
            <b>Parking</b> {{$t('activity changed')}}:
            <br/>
            <template v-if="to.available">
              {{$t(`parking.available.${to.available}`)}},
            </template>
            {{to.price}}€ {{$t(`parking.price_type.${to.price_type}`)}} ({{$t(`parking.space.${to.space}`)}},{{$t(`parking.type.${to.type}`)}})
            <v-chip
                v-if="to.garage"
                small class="mr-1" outlined>
                {{ $t('parking.garage') }}
            </v-chip>
            <v-chip
                v-if="to.secure"
                small class="mr-1" outlined>
                {{ $t('parking.secure') }}
            </v-chip>
            <v-chip
                v-if="to.electric_charging"
                small class="mr-1" outlined>
                {{ $t('parking.electric_charging') }}
            </v-chip>
            <v-chip
                v-if="to.street"
                small class="mr-1" outlined>
                {{ $t('parking.street') }}
            </v-chip>
            <v-chip
                v-if="to.accessible"
                small class="mr-1" outlined>
                {{ $t('parking.accessible') }}
            </v-chip>
        </div>
        <div v-else>
            <template v-if="from">
              <strong>{{ $t(propertyTranslated) }}</strong> {{$t('activity changed')}} {{$t('activity from')}} "{{ from }}" {{$t('activity to')}} "{{to}}"
            </template>
            <template v-else>
              <strong>{{ $t(propertyTranslated) }}</strong>: <span class="word-break-all">"{{to}}"</span>
            </template>
            <!-- <template v-else-if="to">
              <strong>{{ $t(propertyTranslated) }}</strong>: "{{to}}"
            </template> -->
        </div>
    </li>
<!-- $t('Form Info Name') -->
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: ['property', 'activity'],
  data() {
    return {
    //   ignoreKeys: ['selected_tags_2'],
      ignoreKeysOnCreated: [
        'slug',
        'title',
        'category_id',
        'owner_id',
        'city_id',
        'status',
      ],
      ignoreKeyForReservation: [
        'created_by',
        'ad_id',
      ],
      ignoreKeyForUserPayout: [
        'type',
      ],
      ignoreKeys: [
        'provider',
        'provider_id',
      ],
      twoLine: ['description'],
      changeString: {
        // ads
        icals: 'Activity Log Property.icals',
        tags: 'Activity Log Property.tags',
        room_bed_structure: 'Activity Log Property.room_bed_structure',
        city_id: 'Activity Log Property.city_id',
        area_id: 'Activity Log Property.area_id',
        subarea_id: 'Activity Log Property.subarea_id',
        email_verified_at: 'Activity Log Property.email_verified_at',
        profile_photo_updated: 'Activity Log Property.profile_photo_updated',
        government_id_verified_at: 'Activity Log User.government_id_verified_at',
        government_id_government_id_front_uploaded: 'Activity Log User.government_id_government_id_front_uploaded',
        government_id_government_id_back_uploaded: 'Activity Log User.government_id_government_id_back_uploaded',
        // user
        password: 'Activity Log Property.password_changed',
      },
      translations: {
        // ads
        name: 'Form Info Name',
        description: 'Form Info Description',
        guests: 'Form Property Guests',
        m2: 'Form Property m2',
        bedrooms: 'Form Property Bedrooms Title',
        bathrooms: 'Form Property Bathrooms',
        beds: 'Form Property Beds',
        floor: 'Form Location Floor',
        suite_number: 'Form Location SuiteNumber',
        street: 'Form Location Autocomplete Placeholder',
        price: 'Form Pricing Price',
        price_weekend: 'Form Pricing PriceWeekend',
        price_per_guest: 'Form Pricing PricePerGuest',
        weekly_discount: 'Form Pricing WeeklyDiscount',
        monthly_discount: 'Form Pricing MonthlyDiscount',
        charge_after_guest: 'Form Pricing ChangeAfterGuest',
        instant: 'Form Reservation Instant Title',
        checkout: 'Form Reservation CheckOut Title',
        checkin_to: 'Activity Log Property.checkin_to',
        checkin_from: 'Activity Log Property.checkin_from',
        min_stay: 'Form Reservation MinStay',
        min_stay_monday: 'Form Reservation MinStayMonday',
        min_stay_tuesday: 'Form Reservation MinStayTuesday',
        min_stay_wednesday: 'Form Reservation MinStayWednesday',
        min_stay_thursday: 'Form Reservation MinStayThursday',
        min_stay_friday: 'Form Reservation MinStayFriday',
        min_stay_saturday: 'Form Reservation MinStaySaturday',
        min_stay_sunday: 'Form Reservation MinStaySunday',
        min_stay_weekend: 'Form Reservation MinStayWeekend',
        latitude: 'Latitude',
        longitude: 'Longitude',
        // user
        first_name: 'First Name',
        last_name: 'Last Name',
        city: 'City',
        address: 'Address',
        jmbg: 'JMBG',
        pib: 'PIB',
        mb: 'MB',
        account_type: 'Account type',
        company_name: 'Business name',
        phone: 'Phone',
        full_name: 'Full name',
        bank_name: 'Bank name',
        account_number: 'Account number',
        swift: 'SWIFT',
        price_adjustment: 'Activity Log Price Adjustment',

        start: 'Check-in',
        end: 'Check-out',
        tax: 'TAX',
        total: 'Total',
        paid: 'Paid',
        booking_reference: 'Booking reference',
        service_fee: 'Service fee',
        owner_total: 'Host total',
        advance_payment: 'Upfront payment',
        advance_payment_amount: 'Advance payment amount',
        advance_payment_due_date: 'Upfront payment deadline',
        advance_payment_payout_method_id: 'Advance payment payout method',
        advance_payment_paid: 'Advance payment paid',
        delete_reason: 'Activity Log delete reason',
        custom_pricing: 'Custom pricing',
        parking: 'Form Property Parking',
        guest_id: 'Guest ID',
        guest_type: 'Guest type',
        country_id: 'Guest ID',
      },

    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    visible() {
      if (!this.to) {
        if (this.activity.subject_type === 'App\\Calendar\\Rule') {
          return true;
        }
        if (this.activity.subject_type === 'App\\Ads\\Ad' && this.activity.log_name === 'reservation') {
          return true;
        }
        return false;
      }
      // if created &&
      if (this.ignoreKeys.includes(this.property)) {
        return false;
      }
      if ((this.property === 'guest_id' || this.property === 'guest_type') && this.activity.log_name === 'reservation') {
        if (this.isAdmin) {
          return true;
        }
        return false;
      }
      if (this.ignoreKeyForReservation.includes(this.property) && this.activity.log_name === 'reservation') {
        return false;
      }
      if (this.ignoreKeysOnCreated.includes(this.property) && this.activity.event === 'created') {
        return false;
      }
      if (this.ignoreKeyForUserPayout.includes(this.property) && this.activity.subject_type === 'App\\User\\Payout') {
        return false;
      }
      return true;
    },
    template() {
      if (this.activity.event === 'calendar.manual.block') {
        return 'calendar';
      }
      if (this.activity.subject_type === 'App\\User\\Payout') {
        return 'payout';
      }
      if (this.property === 'status') {
        return 'status';
      }
      if (this.property === 'images_added' || this.property === 'images_removed') {
        return 'gallery';
      }
      if ((this.property === 'paid' || this.property === 'custom_pricing' || this.property === 'parking' || this.property === 'advance_payment_paid' || this.property === 'advance_payment') && this.activity.log_name === 'reservation') {
        return 'boolean';
      }
      if (this.property === 'parking' && this.activity.log_name !== 'reservation') {
        return 'parking';
      }
      if (this.property === 'color') {
        return 'color';
      }
      if (this.changeString[this.property]) {
        return 'changeString';
      }
      if (this.twoLine.includes(this.property)) {
        return 'twoLine';
      }
      return undefined;
    },
    from() {
      const from = this.activity.properties.old && this.activity.properties.old[this.property];
      if (this.property === 'instant') {
        return from === 1 ? 'Instant' : this.$t('Form Reservation Upit');
      }
      if (this.property === 'status') {
        return this.$t(`AdFormStatus.${from}`);
      }
      if (this.property === 'account_type') {
        if (from === this.$CONFIG.USER.ACCOUNT_TYPE.INDIVIDUAL) {
          return this.$t('Account type Individual');
        }
        if (from === this.$CONFIG.USER.ACCOUNT_TYPE.BUSINESS) {
          return this.$t('Account type Business');
        }
      }
      if (this.property === 'price_adjustment') {
        if (!from) {
          return ' ';
        }
        return (from > 0)
          ? `+${from}%`
          : `${from}%`;
      }
      return from;
    },
    to() {
      const to = this.activity.properties.attributes[this.property];
      if (this.property === 'instant') {
        return to === 1 ? 'Instant' : this.$t('Form Reservation Upit');
      }
      if (this.property === 'status') {
        // get prev status
        return this.$t(`AdFormStatus.${to}`);
        // const statusOptions = ['info', 'property', 'location', 'tags', 'gallery', 'pricing', 'reservation', 'terms', 'pending', 'pending_fix', 'listed'];
        // const index = statusOptions.findIndex((key) => key === to);
        // return this.$t(`AdFormStatus.${statusOptions[index - 1]}`);
      }
      if (this.property === 'account_type') {
        if (to === this.$CONFIG.USER.ACCOUNT_TYPE.INDIVIDUAL) {
          return this.$t('Account type Individual');
        }
        if (to === this.$CONFIG.USER.ACCOUNT_TYPE.BUSINESS) {
          return this.$t('Account type Business');
        }
      }
      if (this.property === 'price_adjustment') {
        if (!to) {
          return ' ';
        }
        return (to > 0)
          ? `+${to}%`
          : `${to}%`;
      }
      return to;
    },
    propertyTranslated() {
      if (this.translations[this.property]) {
        return this.translations[this.property];
      }
      return this.property;
    },
  },
};
</script>
<style scoped>
.circle-color{
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  display:inline-block;
  border-radius: 50%;
  top: 3px;
  position: relative;
}
</style>
