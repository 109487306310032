<template>
    <li>
        <div v-if="activity.event === 'calendar.manual.block'">
            <b>{{ $t('Activity Log Property.calendar_manual_block') }}</b>
            <br/>
            {{ startEndString }}
        </div>
        <div v-if="activity.event === 'calendar.manual.open'">
            <b>{{ $t('Activity Log Property.calendar_manual_open') }}</b>
            <br/>
            {{ startEndString }}
        </div>
        <div v-if="activity.event === 'calendar.special_price'">
            <b>{{ $t('Activity Log Property.calendar_special_price') }}</b>
            <br/>
            <b>{{activity.properties.attributes.price}}€</b> {{ $t('parking.price_type.per_day').toLowerCase() }} - {{ startEndString }}
        </div>
        <div v-if="activity.event === 'calendar.rule_period'">
            <template v-if="activity.description === 'delete'">
              <b>{{ $t('Activity Log Property.calendar_rule_period_none') }}</b>
              <br/>
              {{ startEndString }}
            </template>
            <template v-else>
              <b>{{ $t('Activity Log Property.calendar_rule_period') }} "<em>{{activity.properties.attributes.rule}}</em>"</b>
              <br/>
              {{ startEndString }}
            </template>

        </div>
    </li>
</template>
<script>
import moment from 'moment';

export default {
  props: ['activity'],
  data() {
    return {
    };
  },
  computed: {
    startEndString() {
      let { start, end } = this.activity.properties.attributes;
      if (start && end) {
        start = moment(start).clone();
        // end = moment(end).clone().subtract(1, 'day');
        end = moment(end).clone();
        const nights = end.diff(start, 'days');
        return `${moment(start).format('D MMM Y')} - ${moment(end).format('D MMM Y')} (${this.$tc('nights_count', nights)})`;
      }
      return undefined;
    },
  },
};
</script>
