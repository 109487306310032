<template>
    <component
        v-bind:is="showInModal ? 'modal' : 'div'"
        :show="show"
        :title="$t('Pricing Preview Title')"
        :hideActionsCancel="true"
        @save="close"
        @close="close"
        :saveText="'Close'"
        :sticky-header="true"
        :showActionsClear="false"
    >
      <div v-if="!errorMessage">
        <div
          class="d-flex flex-column mb-2 px-4 px-sm-8 pricing-preview-wrapper"
          :class="showInModal ? 'pt-4' : 'pt-2'"
        >
          <p v-if="showInModal" class="text-body-2">
            {{ $t(allowChangeDates
              ? 'Pricing Preview Description WithDates'
              : 'Pricing Preview Description') }}
          </p>
          <div class="d-flex flex-column align-center flex-sm-row">
            <pricing-preview-datepicker
              :allowChangeDates="allowChangeDates"
              :disableFilters="disableFilters"
              :start="form.start"
              :end="form.end"
              :id="id"
              class="col-sm-4 pa-0"
              @updateStartEnd="updateStartEnd"
              />

            <v-select
                v-if="numberOfGuests.length"
                :items="numberOfGuests"
                :disabled="disableFilters"
                v-model="guests"
                outlined
                dense
                rounded
                :label="$t('Form Property Guests')"
                class="col-sm-4 px-sm-1 px-md-2"
                prepend-inner-icon="mdi-account-outline"
            />
            <v-select
                v-if="adHasParking"
                :items="parkingOptions"
                :disabled="disableFilters"
                v-model="parking"
                outlined
                dense
                rounded
                :label="$t('Form Property Parking')"
                class="col-sm-4 pl-sm-1 pl-md-2"
                prepend-inner-icon="mdi-car-outline"
            />
            <v-text-field
              v-else
              :value="$t('Form Property No Parking')"
              outlined
              dense
              rounded
              :disabled="true"
              :readonly="true"
              label="Parkinga"
              class="col-sm-4 pl-sm-1 pl-md-2"
            />
          </div>
        </div>
        <div v-if="customPriceComputed" class="px-4 px-sm-8 mb-10">
          <pricing-preview-custom-price :customPrice="customPriceComputed"/>
        </div>
        <div v-else-if="!customPriceComputed && calculator" class="px-4 px-sm-8 mb-10">
          <v-alert
            v-if="
              !calculator.has_enoung_for_min_stay
              && calculator.has_enoung_for_min_stay !== undefined
            "
            color="orange"
            dense
            outlined
            type="info"
            class="text-body-2 mt-4"
          >
            <p class="black--text font-weight-medium my-0">
              {{ $t('pricing-preview-dates-not-enough') }}
              ({{ $tc('nights_count', calculator.start_min_stay) }}).
            </p>
          </v-alert>

          <pricing-preview-calculator :calculator="calculator"/>
        </div>
      </div>

      <div v-else class="px-4 px-sm-8 py-10" >
        <v-alert outlined color="red" class="text-body-1">
          {{ $t(errorMessage) }}
        </v-alert>
      </div>
    </component>
</template>

<script>
import Modal from '@/components/Modal.vue';
import DatePicker from '@/components/DatePickerComponent.vue';
import PricingPreviewCalculator from '@/components/PricingPreviewCalculator.vue';
import PricingPreviewCustomPrice from '@/components/PricingPreviewCustomPrice.vue';
import PricingPreviewDatepicker from '@/components/PricingPreviewDatepicker.vue';
import {
  startEndString,
  DATE_FORMAT,
} from '@/components/calendar/config';
import moment from 'moment';

export default {
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    allowChangeDates: {
      required: false,
      type: Boolean,
    },
    customPrice: {
      required: false,
      type: Object,
    },
    id: {
      required: true,
      type: String,
    },
    start: {
      required: false,
    },
    end: {
      required: false,
    },
    overwrite: {
      required: false,
      type: Object,
    },
    calculatorData: {
      required: false,
      type: Object,
    },
    showInModal: {
      required: false,
      type: Boolean,
      default: true,
    },
    disableFilters: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      guests: 1,
      calculator: undefined,
      parking: 0,
      errorMessage: undefined,
      form: {
        start: undefined,
        end: undefined,
      },
    };
  },
  components: {
    Modal,
    DatePicker,
    PricingPreviewCalculator,
    PricingPreviewDatepicker,
    PricingPreviewCustomPrice,
  },
  mounted() {
    this.form.start = this.start;
    this.form.end = this.end;

    if (this.calculatorData) {
      this.calculator = this.calculatorData;
      this.parking = Number(this.calculator.parking);
      this.guests = Number(this.calculator.guests);
      this.form.start = this.calculator.start;
      this.form.end = this.calculator.end;
    } else {
      this.getPrice();
    }
    // Bind watchers after initial data is set.
    this.$watch('guests', this.guestsWatcher);
    this.$watch('parking', this.parkingWatcher);
  },
  watch: {
    end(value) {
      this.form.end = value;
    },
    start(value) {
      this.form.start = value;
    },
  },
  computed: {
    startEndString,
    parkingOptions() {
      return [
        {
          value: 0,
          text: this.$t('parking.without'),
        },
        {
          value: 1,
          text: this.$t('parking.with'),
        },
      ];
    },
    numberOfGuests() {
      // return Array.from({ length: 8 }, (_, i) => i + 1);
      if (this.calculator && this.calculator.pricing && this.calculator.pricing.guests) {
        return Array.from({ length: this.calculator.pricing.guests }, (_, i) => i + 1);
      }
      return [];
    },
    adHasParking() {
      // return true;
      if (this.calculator && this.calculator.pricing && this.calculator.pricing.parking_available && ['free', 'paid'].includes(this.calculator.pricing.parking_available)) {
        return true;
      }
      return false;
    },
    customPriceComputed() {
      if (!this.customPrice || this.customPrice === undefined) {
        return false;
      }

      if (this.customPrice) {
        return this.customPrice;
      }

      return false;
    },
  },
  methods: {
    guestsWatcher() {
      this.getPrice();
    },
    parkingWatcher() {
      this.getPrice();
    },
    getPrice() {
      this.$store
        .dispatch('calculatePrice', {
          id: this.id,
          start: this.form.start || moment().format(DATE_FORMAT),
          end: this.form.end || moment().add(1, 'day').format(DATE_FORMAT),
          guests: this.guests,
          parking: this.parking,
          overwrite: this.overwrite,
        })
        .then((data) => {
          // console.log('doshao data');
          this.calculator = data;
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.errorMessage = 'Server error';
          }
        });
    },
    close() {
      this.$emit('close');
    },
    updateStartEnd(data) {
      // console.log('updateStartEnd in PricingPReview.vue', data);
      if (data.start && data.end) {
        this.form.start = data.start;
        this.form.end = data.end;
        this.getPrice();
      }
      this.$emit('updateStartEnd', data);
    },
  },

};
</script>

<style scoped lang="scss">
.modal-datepicker-component-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 350px;
  @media (min-width: 480px) {
    height: 630px;
  }
}
.left-border {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.pricing-price-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  & p {
    margin-bottom: 0px;
  }
}
.pricing-table-header{
  padding-inline: 10px;
  width: 0;
  vertical-align: top;
}
.pricing-table-row td:first-child{
 min-width: 120px;
}

.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
}
</style>
