<template>
    <div>
        <v-text-field
            v-if="!allowChangeDates"
            :value="startEndString"
            :disabled="disableFilters"
            :readonly="true"
            :label="$t('Date')"
            class="pr-sm-1 pr-md-2"
            name="startEnd"
            outlined
            dense
            rounded
            prepend-inner-icon="mdi-calendar-blank-outline"
        />
        <v-menu
            v-else
            v-model="showDatepicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="340px"
            class="pa-10"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="startEndString"
                    :disabled="disableFilters"
                    :readonly="true"
                    :label="$t('Date')"
                    class="pr-sm-1 pr-md-2"
                    name="startEnd"
                    outlined
                    dense
                    rounded
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="openDatepicker"
                    prepend-inner-icon="mdi-calendar-blank-outline"
                />
            </template>

            <v-card>
                <v-card-text>
                    <date-picker
                        v-if="events && Array.isArray(events)"
                        ref="pricingDatepicker"
                        :start="start"
                        :end="end"
                        :disabled-dates="disabledDates"
                        @updateStartEnd="updateStartEnd"
                        :css-class="'pricing-preview'"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-btn color="blue darken-1" text @click.stop="close">{{$t('Cancel')}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="canApply" color="primary" text @click.stop="apply">{{$t('Apply')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DatePicker from '@/components/DatePickerComponent.vue';
import {
  startEndString,
  DATE_FORMAT,
  daysBetweenDates,
} from '@/components/calendar/config';
import moment from 'moment';

export default {
  props: {
    allowChangeDates: {
      required: false,
      type: Boolean,
    },
    disableFilters: {
      required: false,
      type: Boolean,
      default: false,
    },
    start: {
      required: false,
    },
    end: {
      required: false,
    },
    id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      showDatepicker: false,
      form: {
        start: undefined,
        end: undefined,
      },
      events: undefined,
    };
  },
  components: {
    DatePicker,
  },
  watch: {
    showDatepicker() {
      if (this.showDatepicker) {
        // get events
        if (this.events === undefined) {
          this.getEvents();
        }
        // remove editReservationId if you want to get  events on FormApartmentPricing
        // if (this.editReservationId) {
        // } else {
        //   this.events = [];
        // }
      } else {
        // this.events = [];
        // console.log('closed');
      }
    },
  },
  computed: {
    ...mapGetters(['editReservationId']),
    startEndString,
    canApply() {
      return this.form.start && this.form.end;
    },
    disabledDates() {
      const dates = [];
      this.events.forEach((event) => {
        // if (event.id !== )
        const eventDates = daysBetweenDates(
          event.start,
          moment(event.end).clone().subtract(1, 'days').startOf('day'),
        );
        for (let i = 0; i < eventDates.length; i += 1) {
          dates.push(eventDates[i]);
        }
      });
      return dates.sort((a, b) => moment(a).diff(moment(b)));
    },
  },
  methods: {
    getEvents() {
      this.$store.dispatch('loading', true);
      this.$store
        .dispatch('calendar', {
          id: this.id,
          start: moment().startOf('month').format(DATE_FORMAT),
          excludeReservationID: this.editReservationId,
        })
        .then((data) => {
          this.events = data;
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          console.warn(error);
          this.errors = error.response.data.errors;
          this.$store.dispatch('loading', false);
        });
    },
    updateStartEnd(dates) {
      // console.log('updateStartEnd', dates);
      this.form.start = dates.start;
      this.form.end = moment(dates.end).add(1, 'day').format(DATE_FORMAT);
    },
    apply() {
      this.$emit('updateStartEnd', {
        start: this.form.start,
        end: this.form.end,
      });
      this.close();
    },
    openDatepicker() {
      if (this.allowChangeDates) {
        this.showDatepicker = true;
      }
    },
    close() {
      if (this.$refs.pricingDatepicker && this.$refs.pricingDatepicker.picker) {
        this.$refs.pricingDatepicker.picker.clear();
        this.$refs.pricingDatepicker.resetRangeToStartEnd();
      }
      this.form.start = undefined;
      this.form.end = undefined;
      this.showDatepicker = false;
    },
  },

};
</script>
