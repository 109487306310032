<template>
  <nav aria-label="Page navigation" v-if="resource && resource.last_page > 1">
    <ul class="light-pagination">
      <li
        v-if="resource.current_page !== 1"
        :class="{ disabled: !resource.prev_page_url }"
        class="prev"
      >
        <a
          :href="resource.prev_page_url"
          @click.prevent="changePage(resource.current_page - 1)"
          aria-label="Previous"
        >
          <span aria-hidden="true">
            <svg
              viewBox="0 0 18 18"
              role="img"
              aria-label="Previous"
              focusable="false"
              style="
                height: 0.8em;
                width: 0.8em;
                display: block;
                fill: currentcolor;
              "
            >
              <path
                d="m13.7 16.29a1 1 0 1 1 -1.42 1.41l-8-8a1 1 0 0 1 0-1.41l8-8a1 1 0 1 1 1.42 1.41l-7.29 7.29z"
                fill-rule="evenodd"
              ></path>
            </svg>
          </span>
        </a>
      </li>
      <div class="flex-container relative pagination-scroll-mobile-container">
        <div class="flex-container pagination-scroll-mobile-div">
          <ul
            class="list-unstyled flex-container"
            :class="{
              'pl-16 ml-6': resource.current_page > 1,
              'pr-16 mr-6': resource.current_page < resource.last_page
            }"
          >
            <template v-if="pages && pages[0] > 1">
              <li>
                <a
                  :href="resource.first_page_url"
                  @click.prevent="changePage(1)"
                  >1</a
                >
              </li>
              <li class="transparent disabled page-dots">...</li>
            </template>
            <li
              v-for="n in pages"
              :key="n"
              :class="{ active: n == resource.current_page }"
            >
              <a
                :href="`${path}?page=${n}`"
                @click.prevent="changePage(n)"
                v-text="n"
              ></a>
            </li>
            <template v-if="pages[pages.length - 1] < resource.last_page">
              <li class="transparent disabled page-dots">...</li>
              <li>
                <a
                  :href="resource.last_page_url"
                  @click.prevent="changePage(resource.last_page)"
                  v-text="resource.last_page"
                ></a>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <li
        v-if="resource.current_page !== resource.last_page"
        :class="{ disabled: !resource.next_page_url }"
        class="next"
      >
        <a
          :href="resource.next_page_url"
          @click.prevent="changePage(resource.current_page + 1)"
          aria-label="Next"
        >
          <span aria-hidden="true">
            <svg
              viewBox="0 0 18 18"
              role="img"
              aria-label="Next"
              focusable="false"
              style="
                height: 0.8em;
                width: 0.8em;
                display: block;
                fill: currentcolor;
              "
            >
              <path
                d="m4.29 1.71a1 1 0 1 1 1.42-1.41l8 8a1 1 0 0 1 0 1.41l-8 8a1 1 0 1 1 -1.42-1.41l7.29-7.29z"
                fill-rule="evenodd"
              ></path>
            </svg>
          </span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.flex-container {
  display: flex;
}
ul.light-pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-top: 40px;
  margin-bottom: 40px;
  overflow: hidden;

  @media (max-width: 767px) {
    .pagination-scroll-mobile-container {
      flex: auto;
    }
  }

  .pagination-scroll-mobile-div {
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 10px;
    @media (max-width: 767px) {
      position: absolute;
      left: 0;
      right: 0;
    }
    ul.list-unstyled {
      margin-left: auto;
      margin-right: auto;
    }
  }

  li {
    display: flex;
    margin-left: 4px;
    margin-right: 4px;
    width: 60px;
    height: 54px;
    border: 1px solid #000;
    border-radius: 4px;
  }

  li a {
    display: flex;
    justify-content: center;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    flex: 1 1 auto;
    align-items: center;
    color: #000;
    // font-family: @fontExtraBold;
    &:hover {
      background: #3f3f3f;
      color: #fff;
    }
  }

  li.page-dots {
    width: 51px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 0;
  }

  li.active a {
    // background-color: @color;
    background-color: #000;
    color: #fff;
    &:hover {
      background-color: #3f3f3f;
    }
  }

  .prev {
    margin-left: 0;
  }

  .next {
    margin-right: 0;
  }

  .prev,
  .next {
    z-index: 99;
    background: #fff;
    @media (max-width: 767px) {
      flex: 0 0 60px;
    }
  }
  .prev a,
  .next a {
    background: transparent;
    border-radius: 0;
    &:hover {
      color: #fff;
    }
  }
}
</style>

<script>
export default {
  props: ['resource', 'overwritePath'],
  methods: {
    changePage(page) {
      const url = `${this.apiPath}?page=${page}`;
      this.$emit('load', url);
    },
  },
  computed: {
    path() {
      return this.resource.path;
    },
    apiPath() {
      return this.overwritePath || this.resource.path;
    },
    pages() {
      const paginator = this.resource;
      const total = paginator.last_page;
      const current = paginator.current_page;
      const visible = 5;
      const offset = 2; // For First page and 3 dots.
      const visibleHalf = Math.floor(visible / 2);
      const startTarget = current - visibleHalf;
      const endTarget = current + visibleHalf;
      const startOffset = Math.max(1, startTarget) - startTarget;
      const endOffset = Math.min(total, endTarget) - endTarget;
      const from = Math.max(startTarget + endOffset, 1) - offset <= 1
        ? 1
        : Math.max(startTarget + endOffset, 1);
      const to = Math.min(endTarget + startOffset, total) + offset >= total
        ? total
        : Math.min(endTarget + startOffset, total);
      return new Array(to - from + 1).fill(from).map((x, i) => x + i);
    },
  },
};
</script>
