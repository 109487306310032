export default {
  computed: {
    uploadDomain() {
      return process.env.VUE_APP_APP_URL;
    },
    translations() {
      return {
        fileTypeNotAllowed: this.$t('validation.media.fileTypeNotAllowed'),
        tooLarge: this.$t('validation.media.tooLarge'),
        tooSmall: this.$t('validation.media.tooSmall'),
        tryAgain: this.$t('validation.media.tryAgain'),
        somethingWentWrong: this.$t('validation.media.somethingWentWrong'),
        selectOrDrag: this.$t('validation.media.selectOrDrag'),
        selectOrDragMax: this.$t('validation.media.selectOrDragMax'),
        file: {
          singular: this.$t('validation.media.file.singular'),
          plural: this.$t('validation.media.file.plural'),
        },
        anyImage: this.$t('validation.media.anyImage'),
        anyVideo: this.$t('validation.media.anyVideo'),
        goBack: this.$t('validation.media.goBack'),
        dropFile: this.$t('validation.media.dropFile'),
        dragHere: this.$t('validation.media.dragHere'),
        remove: this.$t('validation.media.remove'),
      };
    },
  },
};
