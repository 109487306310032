<template>
    <div>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>
                  <b>€ {{ calculator.baseAvg }} x {{ $tc('nights_count', calculator.nights) }}</b><br>
                  {{ $t('Your average nightly price') }}
                </td>
                <td>
                  <span class="text-no-wrap">€ {{ calculator.baseTotal }}</span>
                </td>
              </tr>
              <tr v-if="calculator.extraGuestFee">
                <td>{{ $t('Extra guest fee') }}:</td>
                <td>€ {{ calculator.extraGuestFee }}</td>
              </tr>
              <tr v-if="calculator.parkingTotal">
                <td>
                  <span>
                    {{ $t('Form Property Parking Price For Parking') }}:
                  </span><br>
                  <span v-if="calculator.pricing.parking_price_type === 'per_day'">
                    € {{ calculator.pricing.parking_price }} x {{ $tc('nights_count', calculator.nights) }}
                  </span>
                </td>
                <td>
                  € {{ calculator.parkingTotal }}
                </td>
              </tr>
              <tr v-if="calculator.weeklyDiscount && calculator.discount">
                <td>{{ $t('Form Pricing WeeklyDiscount') }}:</td>
                <td>- € {{ calculator.discount }}</td>
              </tr>
              <tr v-if="calculator.monthlyDiscount && calculator.discount">
                <td>{{ $t('Form Pricing MonthlyDiscount') }}:</td>
                <td>- € {{ calculator.discount }}</td>
              </tr>
              <tr>
                <td>{{ $t('Service fee') }}:</td>
                <td>€ {{ calculator.serviceFee }}</td>
              </tr>
              <tr>
                <td>{{ $t('TAX') }}:</td>
                <td>€ {{ calculator.taxOnServiceFee }}</td>
              </tr>
              <tr class="text-decoration-underline">
                <td><b>{{ $t('Total') }}:</b></td>
                <td class="text-no-wrap"><b>€ {{ calculator.total }}</b></td>
              </tr>
              <tr class="text-decoration-underline">
                <td><b>{{ $t('What you’ll be earning') }}:</b></td>
                <td><b>€ {{ calculator.ownerTotal }}</b></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div style="overflow-x: auto;" v-if="calculator.pricing">
            <h3 class="mt-12 mb-4 text-center">{{ $t('Detailed view of the price per day') }}</h3>
            <v-data-table
              class="pricing-preview-table"
              :headers="headers"
              :items="calculatorDataArrayForTable"
              :items-per-page="500"
              hide-default-footer
              fixed-header
              :disable-pagination="true"
            >
              <template v-slot:item.date="{item}">
                <div>
                  {{ fullDateFormat(item.date.dateString) }}
                  <br/>
                  <v-icon v-if="item.data.isWeekend" small>mdi-check</v-icon>
                  {{ $t(`Day Name Short.${item.date.dayOfWeek}`) }}
                </div>
              </template>
              <template v-slot:item.data.price="{item}">
                <div :class="{ 'text-decoration-line-through': item.data.specialPrice }">
                  € {{ item.data.isWeekend ? calculator.pricing.price_weekend : calculator.pricing.price }}
                </div>
              </template>
              <template v-slot:item.data.specialPrice="{item}">
                <div v-if="item.data.specialPrice">
                  € {{ item.data.specialPrice }}
                </div>
              </template>
              <template v-slot:item.data.priceAdjustment="{item}">
                <div
                  v-if="item.data.priceAdjustment"
                  v-html="priceAdjustmentTemplate(item.data.priceAdjustment)">
                </div>
              </template>
              <template v-slot:item.data.finalPrice="{item}">
                <template v-if="item.data.priceAdjustment">
                  <div class="d-flex flex-sm-column">
                    <span
                      class="text-no-wrap font-weight-bold"
                    >
                      € {{ item.data.price }}
                    </span>
                    <span class="mx-1">=</span>
                    <div class="d-flex">
                      <span v-if="item.data.specialPrice" class="text-no-wrap">€ {{ item.data.specialPrice }}</span>
                      <span v-else class="ml-1 text-no-wrap">
                        € {{ item.data.isWeekend ? calculator.pricing.price_weekend : calculator.pricing.price }}
                      </span>
                      <span class="ml-1 d-flex">*</span>
                      <span class="ml-1" v-html="priceAdjustmentTemplate(item.data.priceAdjustment)"></span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  € {{ item.data.price }}
                </template>
              </template>
              <template v-slot:item.data.extraGuestFee="{item}">
                  <template v-if="item.data.priceAdjustment && item.data.extraGuestFeeAfterPriceAdjustment">
                    <div class="d-flex flex-sm-column">
                      <span
                        class="text-no-wrap font-weight-bold"
                      >
                        € {{ item.data.extraGuestFeeAfterPriceAdjustment }}
                      </span>
                      <span class="mx-1">=</span>
                      <div class="d-flex align-center text-no-wrap">
                        <span>€ {{ item.data.extraGuestFee }}</span>
                        <span class="ml-1">*</span>
                        <span class="ml-1" v-html="priceAdjustmentTemplate(item.data.priceAdjustment)"></span>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    € {{ item.data.extraGuestFeeAfterPriceAdjustment }}
                  </template>
              </template>
              <template v-slot:item.data.parkingFee="{item}">
                <template v-if="item.data.priceAdjustment && item.data.parkingAfterPriceAdjustment">
                  <div class="d-flex flex-sm-column">
                    <span
                      class="text-no-wrap font-weight-bold"
                    >
                      € {{ item.data.parkingAfterPriceAdjustment }}
                    </span>
                    <span class="mx-1">=</span>
                    <div class="d-flex align-center text-no-wrap">
                        <span>€ {{ item.data.parking }}</span>
                        <span class="ml-1">*</span>
                        <span class="ml-1" v-html="priceAdjustmentTemplate(item.data.priceAdjustment)"></span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  € {{ item.data.parkingAfterPriceAdjustment }}
                </template>
              </template>
              <template v-slot:body.append>
                <tr class="v-data-table__mobile-table-row font-weight-bold" v-if="$vuetify.breakpoint.xs">
                  <td class="v-data-table__mobile-row">
                    <div class="v-data-table__mobile-row__header text-decoration-underline">
                      {{ $t('Total') }}
                    </div>
                    <div class="v-data-table__mobile-row__cell">€ {{ calculator.baseTotal }}</div>
                  </td>
                  <td class="v-data-table__mobile-row" v-if="calculator.extraGuestFee">
                    <div class="v-data-table__mobile-row__header text-decoration-underline">
                      {{ $t('Extra guest') }}
                    </div>
                    <div class="v-data-table__mobile-row__cell">€ {{ calculator.extraGuestFee }}</div>
                  </td>
                  <td class="v-data-table__mobile-row" v-if="calculator.parkingTotal">
                    <div class="v-data-table__mobile-row__header text-decoration-underline">
                      {{ $t('Form Property Parking') }}
                    </div>
                    <div class="v-data-table__mobile-row__cell">€ {{ calculator.parkingTotal }}</div>
                  </td>
                </tr>
                <tr class="font-weight-bold" v-else>
                  <td class="text-decoration-underline">{{ $t('Total') }}</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>€{{ calculator.baseTotal }}</td>
                  <td v-if="calculator.extraGuestFee">€{{ calculator.extraGuestFee }}</td>
                  <td v-if="calculator.parkingTotal">€{{ calculator.parkingTotal }}</td>
                  <td>&nbsp;</td>
                </tr>
              </template>
            </v-data-table>

            <h3 class="mt-12 mb-4 text-center">{{$t('Your pricing')}}</h3>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>{{ $t('Base price') }}:</td>
                    <td><b>€ {{ calculator.pricing.price }}</b></td>
                  </tr>
                  <tr>
                    <td>{{ $t('Form Pricing PriceWeekend') }}:</td>
                    <td>
                      <template v-if="calculator.pricing.price_weekend">
                        <b>€ {{ calculator.pricing.price_weekend }}</b>
                      </template>
                      <template v-else>
                        {{ $t('Not set') }}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('Form Pricing WeeklyDiscount') }}:</td>
                    <td><b>{{ calculator.pricing.weekly_discount }}%</b></td>
                  </tr>
                  <tr>
                    <td>{{ $t('Form Pricing MonthlyDiscount') }}:</td>
                    <td><b>{{ calculator.pricing.monthly_discount }}%</b></td>
                  </tr>
                  <tr v-if="calculator.pricing.price_per_guest && calculator.pricing.charge_after_guest">
                    <td>{{ $t('Extra guest fee') }}:</td>
                    <td>
                      <b>€ {{ calculator.pricing.price_per_guest }}</b>,
                      {{ $t('after') }} {{ $tc('extra_guests_count', calculator.pricing.charge_after_guest) }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('Service fee') }}:</td>
                    <td><b>{{ calculator.pricing.sericeFeePercentage }}% + {{ $t('VAT') }}</b></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </div>
    </div>
</template>

<script>
import { fullDateFormat } from '@/components/calendar/config';

export default {
  props: {
    calculator: {
      required: true,
      type: Object,
    },
  },
  mounted() {
    // console.log(this.calculator);
  },
  computed: {
    calculatorDataArrayForTable() {
      const rowsData = [];
      if (this.calculator) {
        // eslint-disable-next-line
        for (const date in this.calculator.dates) {
          const calculatorDataForDate = this.calculator.dates[date];
          rowsData.push({
            data: calculatorDataForDate,
            date: {
              dateString: date,
              dayOfWeek: calculatorDataForDate.dayOfWeek,
            },
          });
        }
      }
      return rowsData;
    },
    headers() {
      const headersArray = [
        { text: this.$t('Date'), value: 'date' },
        { text: this.$t('Base price'), value: 'data.price' },
        { text: this.$t('Special price'), value: 'data.specialPrice' },
        { text: this.$t('Rule price'), value: 'data.priceAdjustment' },
        { text: this.$t('Final price'), value: 'data.finalPrice' },
        { text: this.$t('Extra guest'), value: 'data.extraGuestFee', show: Boolean(this.calculator.extraGuestFee) },
        { text: this.$t('Form Property Parking'), value: 'data.parkingFee', show: Boolean(this.calculator.parkingTotal) },
      ];
      return headersArray.filter((header) => header.show !== false);
    },
  },
  methods: {
    fullDateFormat,
    priceAdjustmentTemplate(priceAdjustment) {
      if (priceAdjustment) {
        if (priceAdjustment < 0) {
          return `<span style="color:red">${priceAdjustment}%</span>`;
        }
        if (priceAdjustment > 0) {
          return `<span style="color:green">+${priceAdjustment}%</span>`;
        }
      }
      return '';
    },
  },
};
</script>
<style scoped lang="scss">
  .pricing-preview-table .v-data-table__wrapper {
    overflow-y: hidden !important;
  }
</style>
