<template>
    <filter-wrapper
      :title="title"
      :isFilterApplied="isFilterApplied"
      @clear="clear"
      @update="update"
      @cancel="cancel"
    >
      <template v-slot:main>
        <div>
          <v-row class="ma-0">
            <v-text-field
              :value="dateString"
              :label="$t('Select date range')"
              prepend-icon="mdi-calendar"
              class="mx-5 mb-3 pa-5 mt-5"
              readonly
            />
          </v-row>
          <v-row justify="center" class="ma-0">
            <v-date-picker
              v-model="dates"
              no-title
              range
              color="primary"
              :max="max"
              class="vuetify-datepicker"
              :locale="$i18n.locale === 'sr' ? 'sr-Latn-RS' : 'en-US'"
            />
          </v-row>
        </div>
      </template>

    </filter-wrapper>
</template>
<script>
import moment from 'moment';
import FilterWrapper from '@/components/Filters/FilterWrapper.vue';

export default {
  components: {
    FilterWrapper,
  },
  props: {
    value: {
      default: [undefined, undefined],
      required: true,
    },
  },
  data() {
    return {
      dates: [undefined, undefined],
      max: moment().format('YYYY-MM-DD'),
    };
  },
  mounted() {
    if (this.value) {
      this.dates = this.value;
    }
  },
  computed: {
    title() {
      if (this.start && !this.end) {
        return this.start;
      }
      if (this.start && this.end) {
        return this.dateString;
      }
      return this.$t('Date');
    },
    start() {
      if (this.dates && this.dates[0]) {
        if (!this.dates[1]) {
          return this.dates[0];
        }
        return (moment(this.dates[0]).diff(moment(this.dates[1])) > 0)
          ? this.dates[1]
          : this.dates[0];
      }
      return null;
    },
    end() {
      if (this.dates && this.dates[0] && this.dates[1]) {
        return (moment(this.dates[0]).diff(moment(this.dates[1])) > 0)
          ? this.dates[0]
          : this.dates[1];
      }
      return null;
    },
    dateString() {
      moment.locale(this.$i18n.locale);
      if (this.start && this.end) {
        return `${moment(this.start).format('D MMM YYYY')} - ${moment(this.end).format('D MMM YYYY')}`;
      }
      if (this.start && !this.end) {
        return moment(this.start).format('D MMM YYYY');
      }
      return '';
    },
    isFilterApplied() {
      return Boolean(this.start);
    },
  },
  methods: {
    cancel() {
      this.dates = this.value;
    },
    clear() {
      this.dates = [undefined, undefined];
      this.$emit('update', this.dates);
    },
    update() {
      if (!this.end) {
        this.dates[1] = this.start;
        this.$emit('update', [this.start, this.start]);
      }
      if (this.start && this.end) {
        this.$emit('update', [this.start, this.end]);
      }
    },
  },
};
</script>
