<template>
    <v-dialog
        v-if="$vuetify.breakpoint.smAndDown"
        v-model="show"
        fullscreen
        hide-overlay
        transition='dialog-bottom-transition'
        light
        >
        <template v-slot:activator="{ on, attrs }">
          <template>
            <v-hover v-slot="{ hover }">
              <v-chip
                outlined
                :class="`${cssClass} single-select-button ${hover || show ? 'active' : ''}`"
                v-bind="attrs"
                v-on="on"
                style="max-width: 250px;"
              >
                <span class="text-truncate">
                  {{title}}
                </span>
                <v-icon v-if="show">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-chip>
            </v-hover>
          </template>
        </template>
        <v-card>
            <v-toolbar flat outlined class="sticky-header">
                <v-toolbar-title class="text-center font-weight-bold">{{title}}</v-toolbar-title>
                <v-spacer/>
                <v-btn icon  @click.stop="cancel">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <slot name="main"></slot>
        </v-card>
        <v-footer fixed>
            <v-row>
                <v-col class="pa-5 flex d-flex justify-space-between align-center" >
                    <span
                      v-if="showCancel"
                      @click.stop="cancel"
                      class="clear"
                    >
                      {{$t('Cancel')}}
                    </span>
                    <span
                      v-else
                      :disabled="isFilterApplied ? true : false"
                      @click.stop="isFilterApplied ? clear() : null"
                      :class="'clear ' + (!isFilterApplied ? 'clear-disabled' : '')"
                    >
                      {{$t('Clear')}}
                    </span>
                    <v-spacer/>
                    <v-btn primary class="primary" text @click.stop="update">{{$t('Apply')}}</v-btn>
                </v-col>
            </v-row>
        </v-footer>
    </v-dialog>
    <v-menu v-else
        v-model="show"
        :left="offsetPositionLeft"
        :close-on-content-click="false"
        @input="menuChanged"
        offset-y
        attach
        min-width="400"
        rounded="lg"
        >
        <template v-slot:activator="{ on, attrs }">
          <template>
            <v-hover v-slot="{ hover }" >
              <v-chip
                outlined
                :class="`${cssClass} single-select-button ${hover || show ? 'active' : ''}`"
                v-bind="attrs"
                v-on="on"
                class="filter-wrapper-chip"
              >
                <span class="text-truncate">
                  {{title}}
                </span>
                <v-icon v-if="show">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-chip>
            </v-hover>
          </template>
        </template>
        <v-card>
            <slot name="main"></slot>
            <v-footer padless outlined color="#fff">
                <div class="px-5 py-3 flex d-flex justify-space-between align-center bt-silver">
                    <span
                      v-if="showCancel"
                      @click.stop="cancel"
                      class="clear"
                    >
                      {{$t('Cancel')}}
                    </span>
                    <span
                      v-else
                      :disabled="isFilterApplied ? true : false"
                      @click.stop="isFilterApplied ? clear() : null"
                      :class="'clear ' + (!isFilterApplied ? 'clear-disabled' : '')">{{$t('Clear')}}
                    </span>
                    <v-btn primary class="primary" text @click.stop="update">{{$t('Apply')}}</v-btn>
                </div>
            </v-footer>
        </v-card>
    </v-menu>
</template>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.sticky-header{
  position: sticky;
  top: 0;
  z-index: 99;
}
.single-select-button{
  margin-right: 12px;
}
.large-button.single-select-button{
  font-weight: 500;
  font-size: 22px !important;
  height: 40px;
  border-radius: 9999px;
  border: none;
}
.large-button.single-select-button.active{
  outline: 2px solid black;
}
@media #{map-get($display-breakpoints, 'sm-and-down')}{
  .large-button.single-select-button{
    font-size: 20px !important;
  }
}

.clear{
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px !important;
}
.clear-disabled{
    cursor: not-allowed !important;
    color: rgb(221, 221, 221) !important;
}
.bt-silver{
    border-top:1px solid rgb(235, 235, 235);
}
.filter-used{
    border-color: rgba(0, 0, 0, 0.5) !important;
}
.outline{
  outline: 1px solid black;
}
.outline::before{
  background: none !important;
}
.filter-wrapper-chip {
  max-width: 250px;
  line-height: normal;
}
</style>
<script>

export default {
  props: ['title', 'isFilterApplied', 'offsetPositionLeft', 'showCancel', 'cssClass'],
  data: () => ({
    show: false,
  }),
  watch: {
    '$vuetify.breakpoint.width': function () {
      this.show = false;
    },
  },
  methods: {
    cancel() {
      this.show = false;
      this.$emit('cancel');
    },
    clear() {
      this.show = false;
      this.$emit('clear');
    },
    update() {
      this.show = false;
      this.$emit('update');
    },
    menuChanged(menu) {
      if (!menu) {
        this.cancel();
      }
    },
  },
};
</script>
