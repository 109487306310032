import moment from 'moment';
import 'moment/locale/sr';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const EVENT_TYPE_ICAL = 'ical';
export const EVENT_TYPE_INQUIRY = 'inquiry';
export const EVENT_TYPE_RESERVATION = 'reservation';
export const EVENT_TYPE_MANUAL = 'manual';
export const EVENT_TYPE_MIN_STAY_BLOCKED = 'min_stay_blocked';
export const DAY_NAMES = [
  'Day Name Short.Monday',
  'Day Name Short.Tuesday',
  'Day Name Short.Wednesday',
  'Day Name Short.Thursday',
  'Day Name Short.Friday',
  'Day Name Short.Saturday',
  'Day Name Short.Sunday',
];

export const RESERVATION_GUEST_TYPE_USER = 'App\\User';
export const RESERVATION_GUEST_TYPE_GUESTBOOK = 'App\\Guestbook\\Guestbook';

export function daysBetweenDates(start, end) {
  const dates = [];

  const currDate = moment(start).subtract(1, 'days').startOf('day');
  const lastDate = moment(end).add(1, 'days').startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().format(DATE_FORMAT));
  }

  return dates;
}

export function humanFriendlyRangeString(startDate, endDate, locale) {
  let start = startDate;
  let end = endDate;

  moment.locale(locale);
  if (start && end) {
    start = moment(start).clone();
    end = moment(end).clone().subtract(1, 'day');
    if (start.year() === end.year()) {
      if (start.month() === end.month()) {
        if (start.date() === end.date()) {
          // return `${moment(start).format('MMM D')}`;
          return `${moment(start).format('ddd MMM D')}`;
        }
        return `${moment(start).format('MMM D')} - ${moment(end).format('D')}`;
      }
      return `${moment(start).format('MMM D')} - ${moment(end).format('MMM D')}`;
    }
    return `${moment(start).format('Y MMM D')} - ${moment(end).format('Y MMM D')}`;
  }
  if (start && !end) {
    return `${moment(start).format('ddd, MMM D')}`;
  }
  return undefined;
}

export function startEndString() {
  moment.locale(this.$i18n.locale);
  const { start } = this;
  const { end } = this;
  return humanFriendlyRangeString(start, end, this.$i18n.locale);
}

export function fullDateFormat(date, showTime) {
  moment.locale(this.$i18n.locale);
  const formats = {
    sr: `D. MMMM YYYY ${showTime ? 'HH:mm' : ''}`,
    en: `MMMM D, YYYY ${showTime ? 'hh:mm A' : ''}`,
  };
  return moment(date).format(formats[this.$i18n.locale]);
}

export function allowOnlyWholeInputNumbers(e) {
  const char = String.fromCharCode(e.keyCode);
  if (/^[0-9]+$/.test(char)) {
    return true;
  }
  return e.preventDefault();
}
