<template>
  <v-timeline-item
    v-if="showLog"
    :fill-dot="true"
    class="mb-10"
    color="#ccc"
    small
  >
    <v-row justify="space-between">
      <v-col cols="12" md="9">
        <!-- <span class="grey--text">{{date}}</span> -->
        <div class="text-h6 grey--text lighten-1">
           <v-chip
              v-if="activity.event === 'created' && activity.subject_type !== 'App\\User\\Detail'"
              class="white--text ml-0"
              color="green"
              label
              small
            >
              {{$t('activity created')}}
            </v-chip>
           <v-chip
              v-if="activity.event === 'deleted'"
              class="white--text ml-0"
              color="red"
              label
              small
            >
              {{$t('activity deleted')}}
            </v-chip>
            {{ subjectName }}
        </div>
        <ul>
          <template v-if="['calendar.manual.block', 'calendar.manual.open', 'calendar.special_price', 'calendar.rule_period'].includes(activity.event)">
            <single :activity="activity"/>
          </template>
          <template v-else
            v-for="key in attributes">
            <Difference :property="key" :activity="activity" :key="key" />
          </template>
        </ul>
        <v-row>
          <v-col>
            <div class="grey--text">
              <div>{{by}}</div>
              <div class="d-none d-sm-flex d-md-none">at {{date}}</div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3" class="text-left text-md-right d-sm-none d-md-flex">
        <span class="grey--text">{{date}}</span>
      </v-col>
    </v-row>
  </v-timeline-item>
</template>

<script>
import moment from 'moment';
import Difference from '@/components/activity-log/Difference.vue';
import Single from '@/components/activity-log/Single.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Difference,
    Single,
  },
  props: ['activity'],
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    subjectName() {
      if (this.activity.subject_type === 'App\\User' || this.activity.subject_type === 'App\\User\\Detail') {
        if (this.activity.event === 'created' && this.activity.subject_type === 'App\\User') {
          return this.$t('Account');
        }
        return this.$t('Account Settings');
      }
      if (this.activity.log_name === 'reservation' && this.activity.subject_type === 'App\\Ads\\Ad' && this.activity.properties && this.activity.properties.booking_reference && this.activity.subject && this.activity.subject.name) {
        return `${this.$t('Reservation')} "${this.activity.properties.booking_reference}" (${this.activity.subject.name})`;
      }
      if (this.activity.subject_type === 'App\\Calendar\\Rule') {
        if (this.activity.properties && this.activity.properties.rule_name) {
          return `${this.$t('Activity Log Calendar Rule')} '${this.activity.properties.rule_name}'`;
        }
        return this.$t('Activity Log Calendar Rule');
      }
      if (this.activity.subject_type === 'App\\User\\Payout') {
        /* eslint-disable camelcase */
        let { payment_type } = this.activity.properties;
        if (payment_type === 'serbia_rsd') {
          payment_type = 'Serbia RSD';
        } else if (payment_type === 'iban') {
          payment_type = 'IBAN';
        } else if (payment_type === 'western_union') {
          payment_type = 'Western Union';
        }
        return `${this.$t('Payout options')} ${this.$t(payment_type)}`;
      }
      if (!this.subject) {
        return '';
      }
      return this.subject.name;
    },
    showLog() {
      if (this.activity && this.activity.properties && this.activity.properties.attributes) {
        const attrs = Object.keys(this.activity.properties.attributes);
        if (this.isAdmin) {
          return true;
        }
        if (attrs.length === 1 && attrs.includes('guest_id')) {
          return false;
        }
        if (attrs.length === 2 && attrs.includes('guest_id') && attrs.includes('guest_type')) {
          return false;
        }
      }
      return true;
    },
    attributes() {
      if (!this.activity.properties.attributes) {
        return [];
      }

      // SET status to be first attribute
      const sortOrder = ['status', 'start', 'end', 'guests', 'parking'];
      const sorter = (a, b) => {
        if (sortOrder.includes(a)) {
          return -1;
        }
        if (sortOrder.includes(b)) {
          return 1;
        }
        return 0;
      };
      return Object.keys(this.activity.properties.attributes).sort(sorter);
    },
    causer() {
      return this.activity.causer;
    },
    subject() {
      return this.activity.subject;
    },
    by() {
      if (this.causer && this.causer.first_name && this.causer.last_name) {
        return `${this.$t('activity By')} ${this.causer.first_name} ${this.causer.last_name}`;
      }
      return '';
    },
    date() {
      return moment(this.activity.created_at).format('D MMM YYYY HH:mm');
    },
  },
};
</script>
